//打开dialog，CardEngine将向后台发出请求，具体的业务模块需要设置参数可以在这个方法里设置
const SETPARAM="setParam";
//打开dialog，将向后台发送请求，如果卡片自身有该方法，则用卡片自身的
const ADDORLOAD = "addOrLoad";
//打开dialog，CardEngine将向后台发出请求，请求完成之后，可以调用该方法
const BEFOREOPEN = "beforeOpen";
//卡片保存之前的方法，如果卡片有定义则执行，如果有且返回false，那么就不会发起保存请求，除非返回rue或者不定义，那么将发起保存请求
const BEFORESAVEHANDLER = "beforeSaveHandler";
//同保存之前一样，提供了保存之后的一些善后处理，有则执行，没有则不管
const AFTERSAVEHANDLER = "afterSaveHandler";
//卡片的保存事件，如果卡片想自己实现保存则重写
const SAVEHANDLER = "saveHandler";
//卡片重置事件，如果卡片有特殊要求需要重写，那么就会调用卡片自己的重置方法
const RESETHANDLER = "resetHandler";
//保存之前，设置卡片数据明细到form表单中某个key，参数是卡片引擎自身
const SETDETAILSJSON = "setDetailsJson";
//加载卡片明细表格数据
const LOADDETAILS="loadDetails";
//删除之前的事件
const BEFOREDELETEHANDLER = "beforeDeleteHandler";
//删除之后的事件
const AFTERDELETEHANDLER = "afterDeleteHandler";
import {ref, reactive, toRefs, onBeforeMount,onMounted, computed, getCurrentInstance, provide, inject, nextTick, defineComponent} from 'vue'

const CardEngineHelper = defineComponent({
    name: 'CardEngine',
    props: {
        engineParams: {
            type: Object
        }
    },
    setup(props,context){
        let dialogInst = inject('dialogInst');
        let {proxy}=getCurrentInstance();
        const utils=proxy.utils;//访问全局对象
        const engineParams=props.engineParams;//外界传到引擎的参数（调用dialog组件传入的参数）
        // console.log(engineParams);
        let dataObj=reactive({
            approveLog:false,//审批日志抽屉显示/影藏控制字段
            reverse:true,//日志排序方式，默认降序排列
            approveLogContentHeight:400,//审批意见默认高度，为了出现滚动条，需要动态计算出审批意见的最大高度
            logInfoArr:[],

            dialogInst:dialogInst,
            modelCompRef:null,
            id:'',//当前正在操作的卡片id，比如删除的时候就会用到
            mainMaxHeight:400,//默认内容的最大高度，实际上在mounted中会动态计算出来
            loading:false,//打开的时候正在加载效果，没有用指令方式是想局部显示正在加载效果
            buttons:[],//头部按钮
            modelComp:engineParams.modelComp,//本次引擎将包含的组件(外界传入的组件)
            modelInst:null//本次引擎将包含的组件实例
        })
        onMounted(async()=>{
            dataObj.loading=true;//打开正在加载效果
            dataObj.approveLogContentHeight=(window.innerHeight-141);//审批意见的高度，设置了高度，那么超过高度就会有滚动条
            dataObj.mainMaxHeight=(window.innerHeight-64);
            dataObj.modelInst=dataObj.modelCompRef;
            dataObj.modelInst.engine=proxy;
            //执行新增或者编辑方法，请求后台，为卡片form表单赋值
            await doAddOrLoad(engineParams.id);
        })
        //---------------------------computed------------
        //按钮事件
        const clickHandler=(ev)=>{
            //如果cardEngine包含的卡片组件有定义该事件，则调用卡片自身的该事件，没有定义则采用cardEngine中默认定义好的事件
            if (dataObj.modelInst[ev]) {
                dataObj.modelInst[ev](proxy);
            } else {
                proxy[ev]();
            }
        }
        //根据id加载卡片，根据id是否为空调用后台的add或则load方法
        const doAddOrLoad=async(id)=>{
            if(!dataObj.loading)dataObj.loading=true;
            if(dataObj.modelInst[ADDORLOAD]){
                await dataObj.modelInst[ADDORLOAD](proxy);
            }else{
                let addOrLoad = "/add";
                //根据id是否为空判断是该发送新增还是编辑请求
                if(utils.$$str.isNotEmpty(id)){
                    addOrLoad = "/load";
                }
                let url = dataObj.modelInst.compParams.modelPath + addOrLoad;
                let params={};
                //即将向后台发送/add或者/load请求，如果业务模块定义有setParam方法，则调用业务模块的该方法进行参数准备
                if (dataObj.modelInst[SETPARAM]){
                    params=await dataObj.modelInst[SETPARAM](proxy);
                }
                //把id放到请求参数中
                params=Object.assign({}, {id: id,modelType:'card'}, params);
                dataObj.id=id;//把当前正在操作的卡片id存起来
                //发送请求并得到请求结果
                let res = await utils.$$api.postRequest({url: url, params: params});
                if(res.result){
                    if(res.data)dataObj.modelInst.form = res.data;//为包含的卡片表单赋值
                    //为cardEngine顶部的buttons赋值
                    dataObj.buttons = res.buttons;
                }
                //向包含的卡片抛出打开回调方法接口
                if (dataObj.modelInst[BEFOREOPEN]) await dataObj.modelInst[BEFOREOPEN](res, addOrLoad,proxy);
                //如果卡片有明细，不管是新增还是编辑都要向后台发送查询明细的请求
                if (dataObj.modelInst.compParams.hsDetails && dataObj.modelInst.compParams.details){
                    let details=dataObj.modelInst.compParams.details;
                    for (const detailType of details) {
                        //如果卡片自己有定义加载明细的方法，则用卡片自己的
                        if(dataObj.modelInst[LOADDETAILS]){
                            await dataObj.modelInst[LOADDETAILS](detailType,id,proxy);
                        }else{
                            await dataObj.modelInst.refMap.get(detailType).reloadGrid({detailType:detailType,mainId:id});
                        }
                    }
                }
                if (dataObj.modelInst['afterOpened']) await dataObj.modelInst['afterOpened'](res, addOrLoad,proxy);
            }
            //关闭正在加载效果
            dataObj.loading=false;
        }
        //保存请求
        const doSave=async(noteFlag)=>{
            //打开正在加载效果
            dataObj.loading=true;
            //如果卡片含有明细信息，需要把明细内容处理为一个json字符串赋值给卡片中form的一个属性
            if (dataObj.modelInst.compParams.hsDetails) {
                if(dataObj.modelInst[SETDETAILSJSON]){
                    await dataObj.modelInst[SETDETAILSJSON](proxy);
                }else{
                    let details=dataObj.modelInst.compParams.details;
                    for (const detailType of details) {
                        let detailData=await dataObj.modelInst.refMap.get(detailType).getSaveDetailData();
                        if(detailData){
                            dataObj.modelInst.form[detailType]=detailData;
                        }else{
                            dataObj.loading=false;//如果明细有验证不成功的情况，则得不到明细数据
                            return;
                        }
                    }
                }
            }
            //向卡片发出保存之前接口回调方法
            if (dataObj.modelInst[BEFORESAVEHANDLER] && !dataObj.modelInst[BEFORESAVEHANDLER](engineParams.contentName,proxy)) {
                dataObj.loading=false;//如果有保存之前校验，且校验失败，那么把正在操作的遮罩层关闭掉
                return;
            }
            if(dataObj.modelInst.compParams.saveConfirm){
                utils.$$tools.configBox({
                    msgContent:dataObj.modelInst.compParams.saveConfirmContent,
                    fn:async ()=> {
                        await ddSave(noteFlag);
                    },
                    cancelFn:()=>{
                        dataObj.loading=false;
                    }
                });
            }else{
                await ddSave(noteFlag);
            }

        }
        const ddSave=async (noteFlag)=>{
            //构造保存请求路径和保存form表单数据
            let url = dataObj.modelInst.compParams.modelPath + "/save";
            //虽然在发请求的时候已经转过string了，但是这里必须也要转，否则后台接收放到map中的时候要报错，空了再去找原因
            let params = JSON.stringify(dataObj.modelInst.form);
            try {
                //发起保存请求并得到请求结果
                let res = await utils.$$api.postRequest({url: url, params: params});
                if(res.result){
                    if(noteFlag)utils.$$tools.success({message: res.msg});
                    //保存成功之后，重新加载form表单
                    await doAddOrLoad(res.data.id);
                    //保存成功之后，如果卡片有明细，则重新加载明细
                    if (dataObj.modelInst.compParams.hsDetails && dataObj.modelInst.compParams.details){
                        let details=dataObj.modelInst.compParams.details;
                        for (const detailType of details) {
                            //如果卡片自己有定义加载明细的方法，则用卡片自己的
                            if(dataObj.modelInst[LOADDETAILS]){
                                await dataObj.modelInst[LOADDETAILS](detailType,res.data.id,proxy);
                            }else{
                                await dataObj.modelInst.refMap.get(detailType).reloadGrid({detailType:detailType,mainId:res.data.id});
                            }
                        }
                    }
                    //向卡片发出保存之后接口回调方法
                    if (dataObj.modelInst[AFTERSAVEHANDLER] && !dataObj.modelInst[AFTERSAVEHANDLER](res,engineParams.contentName,proxy)){
                        dataObj.loading=false;
                        return;
                    }
                    //保存完之后调用列表页的queryHandler，刷新的grid，因为关闭diaolog的时候，不会刷新列表
                    //如果调用打开diaolog的不是列表，则没有queryHandler，就不会调用了
                    if (engineParams.ownerComp && engineParams.ownerComp.queryHandler) engineParams.ownerComp.queryHandler();
                }else{
                    if (dataObj.modelInst['saveFail']){
                        await dataObj.modelInst['saveFail'](res,engineParams.contentName,proxy)
                    }
                    dataObj.loading=false;
                }
            } catch (e) {//如果发起请求后台抛出异常，需要关闭遮罩层，比如保存校验失败抛出异常
                dataObj.loading=false;
            }
        }
        //保存事件
        const saveHandler=()=>{
            //如果卡片自身有保存方法，则调用卡片自身的保存方法
            if (dataObj.modelInst[SAVEHANDLER]) {
                dataObj.modelInst[SAVEHANDLER](proxy);
            } else {
                if (dataObj.modelInst['beforeValidate']){
                    if(!dataObj.modelInst['beforeValidate'](engineParams.contentName,proxy))return;
                }
                //如果卡片的form表单合法则发起保存请求方法
                dataObj.modelInst.formRef.validate(valid => {
                    valid ? doSave(true) : false;
                });
            }
        }
        //重置事件
        const resetHandler=async()=>{
            //如果卡片自身有重置方法，则调用卡片自身的重置方法
            if (dataObj.modelInst[RESETHANDLER]) {
                dataObj.modelInst[RESETHANDLER](engineParams.contentName,proxy);
            } else {
                //如果现在是编辑状态打开dialog，则重新加载一下得到最初状态
                if (utils.$$str.isNotEmpty(dataObj.modelInst.form.id)) {
                    await doAddOrLoad(dataObj.modelInst.form.id);
                } else {
                    //调用el-form自身提供的重置方法重置表单
                    dataObj.modelInst.formRef.resetFields(proxy);
                    if (dataObj.modelInst.compParams.hsDetails && dataObj.modelInst.compParams.details){
                        let details=dataObj.modelInst.compParams.details;
                        for (const detailType of details) {
                            await dataObj.modelInst.refMap.get(detailType).clearDetailData();
                        }
                    }
                }
                utils.$$tools.success({message: "操作成功!"});
            }
        }
        //删除，向后台发送请求
        const doDeleteHandler=async()=>{
            //打开正在加载效果
            dataObj.loading=true;
            if (dataObj.modelInst[BEFOREDELETEHANDLER] && !dataObj.modelInst[BEFOREDELETEHANDLER](dataObj.id,proxy)) {
                dataObj.loading=false;
                return;
            }
            //构造提交请求路径
            let url = dataObj.modelInst.compParams.modelPath + '/delete';
            //发起提交请求并得到请求结果
            let res = await utils.$$api.postRequest({url: url, params: {id: dataObj.id}});
            //关闭正在加载效果
            dataObj.loading=false;
            if(res.result){
                utils.$$tools.success({message: res.msg});
                if (dataObj.modelInst[AFTERDELETEHANDLER]) dataObj.modelInst[AFTERDELETEHANDLER](dataObj.id,proxy)
                //删除之后会关闭dialog，调用列表页的queryHandler，刷新的grid
                if (engineParams.ownerComp && engineParams.ownerComp.queryHandler) engineParams.ownerComp.queryHandler();
                dialogInst.dialogVisible=false;//交给elementui自己去关闭吧
            }
        }
        //删除事件
        const delHandler=()=>{
            if(utils.$$str.isNotEmpty(dataObj.id)){
                utils.$$tools.configBox({
                    msgContent:'确定删除该条记录吗？',
                    fn:async ()=>{
                        await doDeleteHandler();
                    }
                });
            }else{
                proxy.$message('新增状态，不能删除！');
            }
        }
        //审批日志
        const verifyHisHandler=async ()=>{
            let url = '/verifyHis/custom';
            let res = await utils.$$api.postRequest({ url: url, params: {id:dataObj.id,operateType:'approveLog'}});
            // console.log(res);
            dataObj.logInfoArr=res.resultData;
            dataObj.approveLog=true;
        }
        return{
            ...toRefs(dataObj),clickHandler,doAddOrLoad,doSave,saveHandler,resetHandler,doDeleteHandler,delHandler,verifyHisHandler
        }
    }
});
export default CardEngineHelper;